import React from 'react'

export default {
  title_pt: 'Rotesma - Tecnologia',
  title_en: 'Rotesma - Technology',
  title_es: 'Rotesma - Tecnología',
  software_pt: <>Utilizamos os melhores softwares do mundo para <br/>a indústria de pré&#8209;fabricado<br/> de concreto.</>,
  software_en: <>Utilizamos os melhores softwares do mundo para <br/>a indústria de pré&#8209;fabricado<br/> de concreto.</>,
  software_es: <>Utilizamos el mejor software del mundo para <br/>la industria de prefabricados<br/> de hormigón.</>,
  software_description_pt: 'Nossa tecnologia é aplicada em cada obra pré-fabricadas de concreto. Todos os processos estão interligados 100% do tempo, permitindo agilidade no projeto, produção, logística e montagem da sua estrutura',
  software_description_en: 'Nossa tecnologia é aplicada em cada obra pré-fabricadas de concreto. Todos os processos estão interligados 100% do tempo, permitindo agilidade no projeto, produção, logística e montagem da sua estrutura',
  software_description_es: 'Nuestra tecnología se aplica en cada obra de prefabricado de hormigón. Todos los procesos están interconectados el 100% del tiempo, lo que permite agilidad en el diseño, producción, logística y montaje de su estructura',
  bim_pt: 'Plataforma mundial de virtualização, modelagem da informação, gerenciamento de todas as atividades inerentes ao projeto de obras.',
  bim_en: 'Plataforma mundial de virtualização, modelagem da informação, gerenciamento de todas as atividades inerentes ao projeto de obras.',
  bim_es: 'Plataforma mundial de virtualización, modelado de información, gestión de todas las actividades inherentes al proyecto de construcción.',
  tekla_pt: 'Sistema finlandês em plataforma BIM, mais adotado no mundo, para modelagem, detalhamento, compatibilização, conferência e revisão de projetos. Traduz maior qualidade e precisão das informações.',
  tekla_en: 'Sistema finlandês em plataforma BIM, mais adotado no mundo, para modelagem, detalhamento, compatibilização, conferência e revisão de projetos. Traduz maior qualidade e precisão das informações.',
  tekla_es: 'Sistema finlandés en plataforma BIM, más adoptado en el mundo, para modelado, detallado, compatibilidad, conferencias y revisión de proyectos. Traduce mayor calidad y precisión de la información.',
  tqs_pt: 'Software destinado à elaboração de projetos estruturais de edificações em concreto armado e protendido.',
  tqs_en: 'Software destinado à elaboração de projetos estruturais de edificações em concreto armado e protendido.',
  tqs_es: 'Software para la elaboración de proyectos estructurales de edificaciones en hormigón armado y pretensado.',
  plannix_pt: 'Sistema voltado a eficiência da logística de produção, transporte e montagem de estruturas pré-fabricadas.',
  plannix_en: 'Sistema voltado a eficiência da logística de produção, transporte e montagem de estruturas pré-fabricadas.',
  plannix_es: 'Sistema destinado a la eficiencia de la logística de producción, transporte y montaje de estructuras prefabricadas.',
  materials_pt: 'Cuidado especial e uma fórmula própria de selecionar e combinar duas matérias primas:',
  materials_en: 'Cuidado especial e uma fórmula própria de selecionar e combinar duas matérias primas:',
  materials_es: 'Cuidado especial y una fórmula propia para seleccionar y combinar dos materias primas:',
  material_1_pt: <><b>Areia</b> e Brita vem de seleção premium;</>,
  material_1_en: <><b>Areia</b> e Brita vem de seleção premium;</>,
  material_1_es: <><b>Arena</b> y piedra que vienen de una selección premium;</>,
  material_2_pt: <><b>Concreto 100% autoadensável</b> com cimento A.R.I, trazendo maior segurança e perfeito acabamento externo;</>,
  material_2_en: <><b>Concreto 100% autoadensável</b> com cimento A.R.I, trazendo maior segurança e perfeito acabamento externo;</>,
  material_2_es: <><b>Hormigón 100% autocompactante</b> con cemento de alta resistencia inicial (A.R.I), proporcionando mayor seguridad y una perfecta terminación exterior;</>,
  material_3_pt: <><b>Aditivos Especiais</b>, que potencializam e asseguram alta qualidade, fluidez, rapidez e acabamentos uniformes ao concreto;</>,
  material_3_en: <><b>Aditivos Especiais</b>, que potencializam e asseguram alta qualidade, fluidez, rapidez e acabamentos uniformes ao concreto;</>,
  material_3_es: <><b>Aditivos Especiales</b>, que potencian y aseguran alta calidad, fluidez, rapidez y terminaciones uniformes al hormigón;</>,
  material_4_pt: <><b>Ferragem</b> cortada, dobrada e pré-armada em equipamentos industriais de precisão, em modo automatizado a partir dos comandos gerados do BIM.</>,
  material_4_en: <><b>Ferragem</b> cortada, dobrada e pré-armada em equipamentos industriais de precisão, em modo automatizado a partir dos comandos gerados do BIM.</>,
  material_4_es: <><b>Herrajes</b> cortados, doblados y premontados en equipos industriales de precisión, en modo automatizado a partir de los comandos generados desde BIM.</>
}
