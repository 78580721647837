import React from 'react'
import { Footer, Grid, Head, Translate } from '@components'
import locale from '@data/locales/technology'
import * as st from '@assets/styl/Technology.module.styl'

import brita from '@assets/img/brita.jpg'
import concreto from '@assets/img/concreto.jpg'
import aditivosEspeciais from '@assets/img/aditivos-especiais.jpg'
import ferragem from '@assets/img/ferragem.jpg'

export default ({ location: { pathname } }) => <>
  {Translate(locale, 'title', title => <Head title={title} path={pathname} />)}

  <section className={st.software} id="technology-software">
    <Grid wide>
      <div className={st.titleWrapper}>
        <h3>{Translate(locale, 'software')}</h3>
      </div>
      <p>{Translate(locale, 'software_description')}</p>
    </Grid>
    <Grid className={st.grid}>
      <ul>
        <li>
          <h4>BIM</h4>
          <p>{Translate(locale, 'bim')}</p>
        </li>
        <li>
          <h4>Tekla</h4>
          <p>{Translate(locale, 'tekla')}</p>
        </li>
      </ul>
      <ul>
        <li>
          <h4>TQS</h4>
          <p>{Translate(locale, 'tqs')}</p>
        </li>
        <li>
          <h4>Plannix</h4>
          <p>{Translate(locale, 'plannix')}</p>
        </li>
      </ul>
    </Grid>
  </section>

  <section className={st.materials} id="technology-materials"><div>
    <Grid className={st.grid}>
      <h3>{Translate(locale, 'materials')}</h3>
    </Grid>
    <Grid wide>
      <ol>
        <li style={{ backgroundImage: `url(${brita})` }}>
          <div className={st.img}>
            <img src={brita} />
          </div>
          <span>{Translate(locale, 'material_1')}</span>
        </li>
        <li style={{ backgroundImage: `url(${concreto})` }}>
          <div className={st.img}>
            <img src={concreto} />
          </div>
          <span>{Translate(locale, 'material_2')}</span>
        </li>
        <li style={{ backgroundImage: `url(${aditivosEspeciais})` }}>
          <div className={st.img}>
            <img src={aditivosEspeciais} />
          </div>
          <span>{Translate(locale, 'material_3')}</span>
        </li>
        <li style={{ backgroundImage: `url(${ferragem})` }}>
          <div className={st.img}>
            <img src={ferragem} />
          </div>
          <span>{Translate(locale, 'material_4')}</span>
        </li>
      </ol>
    </Grid>
  </div></section>

  <Footer />
</>